import React from "react";

const ShopLocationMap = () => {
  return (
    <div style={mapContainerStyle}>
      <iframe
        title="Shop Location"
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d567.0064447763571!2d76.96548709198483!3d10.991010234139335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1729345735422!5m2!1sen!2sin"
        width="600"
        height="450"
        style={iframeStyle}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
};
const mapContainerStyle = {
  textAlign: "center",
  margin: "20px auto",
};

const iframeStyle = {
  border: "0",
  width: "100%",
  height: "400px",
};

export default ShopLocationMap;
