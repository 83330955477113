import React, { useContext } from "react";
import SignUp from "../sign/SignUp.jsx";
import SignIn from "../sign/SignIn.jsx";
import { signInWithGooglePop, createUserDocument } from "../utils/firebase.js";
import Button from "../ button/Button.jsx";
import { UserContext } from "../contexts/UserContext.jsx";
import { singOutUser } from "../utils/firebase.js";
import useStore from '../store/store.js';
import '../ button/button.scss'
import { Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Orders from "./Orders.jsx";
function Auth() {
  
  const user=useStore((state) => state.user)
  // const {setUser}=useContext(UserContext);
  // let {user}=useContext(UserContext);
  const setUser = useStore((state) => state.setUser);
  const signinwithgoogle = async () => {
    const res = await signInWithGooglePop();
    const user = await createUserDocument(res.user);
    const photo=res.user.photoURL;
    setUser({user,photoUrl:photo});

  };
  const test=()=>{
    singOutUser();
  }
  return (
    <>
      {/* <SignIn /> */}
      {user?<h2>{user.user.displayName}</h2>:
      <><div onClick={signinwithgoogle}>
      <Button buttonType={"google"}>
        Google SignIn
      </Button>
      </div>
      <Divider/>
      <Link to={'/payment'}>
      <Button buttonType={"inverted"}>
        Continue Without Sign IN
      </Button>
      </Link>
</>}
      
      {/* <SignUp /> */}
{user?<Orders userEmail={user.user.email}/>:<></>}
      

    </>
  );
}

export default Auth;
