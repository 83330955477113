import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';

const SoloCompiler = () => {
    const [qrCodeUrl, setQrCodeUrl] = useState('');

    const businessDetails = `
        Business Name: The Solo Compiler
        Tagline: Coding Crafted with Precision
        Website: www.thesolocompiler.com
        Email: thesolocompiler@gmail.com
        Phone: +1 (123) 456-7890
        Location: Remote/Online Services
    `;

    useEffect(() => {
        QRCode.toDataURL(businessDetails)
            .then((url) => setQrCodeUrl(url))
            .catch((err) => console.error(err));
    }, [businessDetails]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #ddd',
            borderRadius: '8px',
            padding: '20px',
            width: '100%',
            backgroundColor: 'white',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Arial, sans-serif',
            textAlign: 'center',
            marginTop: '20px',
        }}>
            <p style={{ backgroundColor:'white',color: 'black', fontSize: '14px', marginBottom: '15px', fontStyle: 'italic' }}>
                This website is built using The Solo Compiler
            </p>
            <h2 style={{ backgroundColor:'white',color: 'black', fontSize: '20px', marginBottom: '10px' }}>The Solo Compiler</h2>
            <p style={{ backgroundColor:'white',color: 'black', fontSize: '14px', fontStyle: 'italic', marginBottom: '20px' }}>
                Coding Crafted with Precision
            </p>
            {qrCodeUrl ? (
                <img src={qrCodeUrl} alt="QR Code" width={'70%'} height={'70%'} style={{ marginBottom: '15px' }} />
            ) : (
                <p>Generating QR Code...</p>
            )}
            <div style={{ backgroundColor:'white',color: 'black', fontSize: '12px', lineHeight: '1.5',width:'100%' }}>
                <p style={{ backgroundColor:'white',color: 'black'}}>Website: <a href="https://www.thesolocompiler.com" target="_blank" rel="noopener noreferrer" style={{ color: '#007bff' }}>thesolocompiler.com</a></p>
                <p style={{ backgroundColor:'white',color: 'black'}}>Email: thesolocompiler@gmail.com</p>
                <p style={{ backgroundColor:'white',color: 'black'}}>Phone: +91-8015936676</p>
                <p style={{ backgroundColor:'white',color: 'black'}}>Location: Remote/Online Services</p>
            </div>
        </div>
    );
};

export default SoloCompiler;
