// SendEmailButton.js
import React from 'react';
// const baseUrl="http://localhost:3002";
const baseUrl="https://saliheenbackend.onrender.com";
const SendEmailButton = () => {
  return (
    <button>
      Send Order Confirmation Email
    </button>
  );
};

export const handleSendEmail = async ({customerEmail,orderDetails}) => {
  

  try {
    // const response = await fetch('https://saliheenbackend.onrender.com/api/send-order-email', {
    const response = await fetch(`${baseUrl}/api/send-order-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ customerEmail, orderDetails }),
    });

    if (response.ok) {
      console.log('Order confirmation email sent successfully');
      alert('Email sent successfully!');
    } else {
      console.log('Failed to send order confirmation email');
      alert('Failed to send email.');
    }
  } catch (error) {
    console.error('Error sending email:', error);
    alert('Error sending email.');
  }
}


export default SendEmailButton;


