import React from 'react';
import './button.scss'
const Button_styles={
    google:'google-sign-in',
    inverted:'inverted',

}
function Button({children,buttonType}) {
  return (
    <button className={`button-container ${Button_styles[buttonType]}`}>{children}</button>
  )
}

export default Button