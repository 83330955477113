import { createContext, useState } from "react";

export let UserContext=createContext({
user:null,
setUser:()=>null
});

export let UserProvider=({children})=>{
    let [user,setUser]=useState(null);
    let value={user,setUser};


return (
    <UserContext.Provider value={value}>{children}</UserContext.Provider>
)
}