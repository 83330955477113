import React from "react";
import { Outlet } from "react-router";
import { Link, useLocation } from 'react-router-dom';
import "../App.scss";
const Footer = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      <Outlet />
      <div className="Footer">
        <Link
          className={`footiconcont ${currentPath === '/' ? 'selected' : ''}`}
          to={'/'}
        >
          <i className="fa-solid fa-house homeicon"></i>
          <span>Home</span>
        </Link>
        <Link
          className={`footiconcont ${currentPath === '/inspired' ? 'selected' : ''}`}
          to={'/inspired'}
        >
          <i className="fa-solid fa-lightbulb homeicon"></i>
          <span>Inspired</span>
        </Link>
        <Link
          className={`footiconcont ${currentPath === '/custom' ? 'selected' : ''}`}
          to={'/custom'}
        >
          <i className="fa-solid fa-flask homeicon"></i>
          <span>Custom</span>
        </Link>
        <Link
          className={`footiconcont ${currentPath === '/luxury' ? 'selected' : ''}`}
          to={'/luxury'}
        >
          <i className="fa-solid fa-spray-can-sparkles homeicon"></i>
          <span>Luxury</span>
        </Link>
        <Link
          className={`footiconcont ${currentPath === '/cart' ? 'selected' : ''}`}
          to={'/cart'}
        >
          <i className="fa-solid fa-cart-shopping homeicon"></i>
          <span>Cart</span>
        </Link>

        
      </div>
    </>
  );
};

export default Footer;
