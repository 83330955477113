import "./App.scss";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import { Route, Routes } from "react-router-dom";
import Inspired from "./components/Inspired";
import Custom from "./components/Custom";
import Luxury from "./components/Luxury";
import Cart from "./components/Cart";
import SignIn from "./components/SignIn";
import ContactUs from "./components/ContactUs";
import { useEffect } from "react";
import axios from "axios";
import PaymentComponent from "./components/PaymentComponent";
import TermsAndConditions from "./components/TermsAndConditions";
import OrderManagement from "./components/OrderManagement";
import SoloCompiler from "./components/SoloCompiler";

function App() {
    return (
    <div className="App">
      <Header />
      <main className="Main">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/inspired" element={<Inspired />} />
          <Route path="/custom" element={<Custom />} />
          <Route path="/luxury" element={<Luxury />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/payment" element={<PaymentComponent />} />
          <Route path="/tandc" element={<TermsAndConditions />} />
          <Route path="/admin" element={<OrderManagement />} />
          <Route path="/solocompiler" element={<SoloCompiler/>} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
