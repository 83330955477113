import React, { useState, useEffect } from 'react';
import useStore from '../store/store.js';
import Button from '../ button/Button.jsx';
import { Link } from 'react-router-dom';
import AttarOrPerfume from './AttarOrPerfume.jsx';
import { Divider } from 'semantic-ui-react';

const Cart = () => {
  const cart = useStore((state) => state.cart);
  const user = useStore((state) => state.user);
  const removeFromCart = useStore((state) => state.removeFromCart);
  const updateCartItem = useStore((state) => state.updateCartItem);

  // Initialize quantities based on item.quantity or default to 3ml
  const [quantities, setQuantities] = useState(
    cart.map((item) => ({
      ml: item.quantity ? item.quantity : 3, // If item has quantity, use it, otherwise default to 3ml
      total: (item.quantity ? item.quantity : 3) * item.price,
    }))
  );

  // Handle quantity change and recalculate the total price
  const handleQuantityChange = (index, event) => {
    const selectedMl = Number(event.target.value);
    const updatedQuantities = [...quantities];
    updatedQuantities[index] = { ml: selectedMl, total: selectedMl * cart[index].price };
    setQuantities(updatedQuantities);

    // Update the cart in the global store based on id
    const id = cart[index].id;
    const updatedItem = { ...cart.find((item) => item.id === id), quantity: selectedMl };
    updateCartItem(id, updatedItem);
  };

  return (
    <div className="cart">
      <h2>Your Cart</h2>
      {cart.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <ul>
          {cart.map(({ image, item, price, id,type ,category}, index) => (
            <div className="cartItemContainer" key={id}>
              <img className="cartImage" src={image} alt={item} />
              <div>
                <p className="legend carousel-legend">{item}</p>
                <p className="legend carousel-legend">Price per ml: ₹{price}</p>
                <label>
                  Select quantity:
                  <select
                    value={quantities[index].ml}
                    onChange={(event) => handleQuantityChange(index, event)}
                  >
                    <option value="3">3ml</option>
                    <option value="6">6ml</option>
                    <option value="12">12ml</option>
                    <option value="24">24ml</option>
                  </select>
                </label>
                <Divider />
                <AttarOrPerfume type={type} category={category}/>
                <Divider />
                <p className="legend carousel-legend">
                  Total for this item: ₹{quantities[index].total}
                </p>
              </div>
              <button onClick={() => removeFromCart({ item, price, image })}>X</button>
            </div>
          ))}
        </ul>
      )}
      {cart.length > 0 && (
        <>
          <h2>
            Total: ₹{cart.reduce((total, item, index) => total + quantities[index].total, 0)}
          </h2>
          <Link to={user ? '/payment' : '/signin'}>
            <Button className="cartBuyNow" buttonType="inverted">
              Buy Now
            </Button>
          </Link>
        </>
      )}
    </div>
  );
};

export default Cart;
