import axios from 'axios'
const addToMongo=()=>{
console.log('hello')
}









export const addToCartBackend=(item)=>{
    // console.log(item);
addToMongo();
}


