import React, { useContext } from 'react'
import Contact from './Contact';
import './ContactUs.scss'
function ContactUs() {
  return (
    <>
    <div className="baritems contactus">
      <Contact />
  </div>
  
    </>
  )
}

export default ContactUs