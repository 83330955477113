import React from "react";
import useStore from '../store/store.js';
import { addToCartBackend } from "../utils/mongoUtils.js";
import { Button, Divider, Icon } from 'semantic-ui-react';
import './Products.scss'

function PerItem({images,category,type}) {
    const addToCart = useStore((state) => state.addToCart);
    const cart = useStore((state) => state.cart); // Assuming `cart` is part of the store state
    const user = useStore((state) => state.user);
  
    // Add item to cart function
    const add = ({ item, price, image, id ,quantity=3}) => {
      price=Number(price);
      addToCart({ item, price, image, id ,quantity,category,type});
      addToCartBackend({ item, price, user });
    };
  
    return (
      <div className="baritems">
        <div className="productsContainer">
          {images.map((image, index) => {
            const fileName = image.split("/").pop().split(".")[0];
            const [item, desc, price] = fileName.split("_");
            const id = item;
            
            // Check if item is in the cart
            const isInCart = cart.some(cartItem => cartItem.id === id);
  
            return (
              <div className="products" key={index}>
                <img className="productimage" src={image} alt={`Image ${index + 1}`} />
                <p className="la-belle-aurore-regular">{item}</p>
                <p className="peritem">{desc}</p>
                <p className="peritem">₹{price} per ml</p>
                
                {isInCart ? (
                  <div className="leftrightauto">
                  <Button  basic color="yellow">
                    <Icon name="check" />
                    Added
                  </Button>
  
                  </div>
                ) : (
                  <div className="leftrightauto">
                  <Button onClick={() => add({ item, price, image, id,category,type })} basic color="yellow">
                    <Icon name="cart plus" />
                    Add to Cart
                  </Button>
                  </div>
                )}
                <Divider/>
              </div>
            );
          })}
        </div>
      </div>
    );
}

export default PerItem