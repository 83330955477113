import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './payment-component.css';
import useStore from '../store/store.js';
import { Divider } from 'semantic-ui-react';
import { handleSendEmail } from './SendEmailButton.jsx';
import { Button } from 'semantic-ui-react';
const PaymentComponent = () => {
    // const baseUrl="http://localhost:3002";
    const baseUrl="https://saliheenbackend.onrender.com";
    const [contact, setContact] = useState('');
    const [amount, setAmount] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('cod');
    
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState('');

    const cart = useStore((state) => state.cart); // Access cart from global store
    const user = useStore((state) => state.user); // Access cart from global store
    const [name, setName] = useState(user&&user.user.displayName||'');
    const [email, setEmail] = useState(user&&user.user.email||'');
    const handleOrder = async () => {
        if (name && email && contact && amount && street && city && state && zip) {
            if (cart.length > 0) {
                try {
                    let address={ street, city, state, zip, country }
                    // 1. Find or create the user by email
                    const userResponse = await axios.post(`${baseUrl}/api/users/auth/google`, {
                        email,
                        name,
                        contact,
                        address
                    });
    
                    const userId = userResponse.data._id;  // Extract user ID
    
                    // 2. Prepare order details
                    const orderDetails = {
                        user:userId,  // Use the user ID
                        totalPrice:amount,
                        name,
                        contact,
                        paymentMethod:paymentMethod=='cod'&&'Cash on Delivery',
                        shippingAddress: address,
                        orderItems: cart.map(item => ({
                            itemName: item.item,
                            quantity: item.quantity,
                            itemPrice: item.price * item.quantity,
                        })),
                        shippingAddress: {
                            street,
                            city,
                            state,
                            zip,
                            country,
                        },
                        status: 'Pending',
                    };
    
                    // 3. Make the order request
                    const orderResponse = await fetch(`${baseUrl}/api/orders`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(orderDetails),
                    });
    
                    if (orderResponse.ok) {
                        const result = await orderResponse.json();
                        handleSendEmail({customerEmail:email,orderDetails:result})
                        alert('Order placed successfully!');
                    } else {
                        const error = await orderResponse.json();
                        console.error('Error placing order:', error);
                        alert('Error placing order: ' + error.message);
                    }
                } catch (err) {
                    console.error('Request failed:', err);
                    alert('Request failed: ' + err.message);
                }
            }
        } else {
            alert('Please fill in all required fields to place the order.');
        }
    };
    

    useEffect(() => {
        // Recalculate the total amount every time the cart is updated
        const totalAmount = cart.reduce((total, item) => total + (item.price * item.quantity), 0);
        setAmount(totalAmount);
    }, [cart]); // Dependency on cart to trigger recalculation when cart changes

    const handleOnlinePayment = async () => {
        // Payment handler logic here
    };

    const handlePayment = () => {
        if (paymentMethod === 'online') {
            handleOnlinePayment();
        } else {
            handleOrder();
        }
    };

    return (
        <div className="payment-container">
            <h2>Complete Your Payment</h2>

            <form className="payment-form">
                <label>Name:</label>
                <input
                    type="text"
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your name"
                />

                <label>Email:</label>
                <input
                    type="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                />

                <label>Contact:</label>
                <input
                    type="tel"
                    value={contact}
                    required
                    onChange={(e) => setContact(e.target.value)}
                    placeholder="Enter your contact number"
                />

                <Divider />

                <h3>Shipping Address</h3>
                <label>Street:</label>
                <input
                    type="text"
                    value={street}
                    required
                    onChange={(e) => setStreet(e.target.value)}
                    placeholder="Enter your street address"
                />

                <label>City:</label>
                <input
                    type="text"
                    value={city}
                    required
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="Enter your city"
                />

                <label>State:</label>
                <input
                    type="text"
                    value={state}
                    required
                    onChange={(e) => setState(e.target.value)}
                    placeholder="Enter your state"
                />

                <label>Zip Code:</label>
                <input
                    type="text"
                    value={zip}
                    required
                    onChange={(e) => setZip(e.target.value)}
                    placeholder="Enter your zip code"
                />

                <label>Country:</label>
                <input
                    type="text"
                    value={country}
                    required
                    onChange={(e) => setCountry(e.target.value)}
                    placeholder="Enter your country"
                />

                <Divider />

                <h3>Order Summary</h3>
                <table className="summary-table">
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Quantity (ml)</th>
                            <th>Price (₹)</th>
                            <th>Total (₹)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cart.map((item) => (
                            <tr key={item.id}>
                                <td>{item.item}</td>
                                <td>{item.quantity}ml</td>
                                <td>₹{item.price}</td>
                                <td>₹{item.price * item.quantity}</td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan="3"><strong>Total</strong></td>
                            <td><strong>₹{amount}</strong></td>
                        </tr>
                    </tbody>
                </table>

                <Divider />

                <div className="payment-method">
                    <div>
                        <input
                            type="radio"
                            id="online"
                            name="paymentMethod"
                            value="online"
                            disabled
                            // checked={paymentMethod === 'online'}
                            onChange={() => setPaymentMethod('online')}
                        />
                        <label htmlFor="online">Online Payment</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id="cod"
                            name="paymentMethod"
                            value="cod"
                            checked={paymentMethod === 'cod'}
                            onChange={() => setPaymentMethod('cod')}
                        />
                        <label htmlFor="cod">Cash on Delivery</label>
                    </div>
                </div>
                <button type="button" onClick={handlePayment}>
                    {paymentMethod === 'cod' ? 'Place Order' : 'Pay Now'}
                </button>
            </form>
        </div>
    );
};

export default PaymentComponent;
