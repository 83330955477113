// store.js
import {create} from 'zustand';

// Define Zustand store
const useStore = create((set) => ({
  // Cart state
  cart: [],
  // User authentication state
  user: null,

  // Actions to modify state
  addToCart: (product) => set((state) => {
    console.log(product);
    console.log(state);
    return {
    cart: [...state.cart, product]
  }}),

  removeFromCart: (productId) => set((state) => ({
    cart: state.cart.filter((product) => product.id !== productId)
  })),

  clearCart: () => set(() => ({
    cart: []
  })),

  setUser: (user) => set((state) => {
    console.log(user)
    return {
    user:user,
    imageUrl:user.photoUrl
  }}),

  logout: () => set(() => ({
    user: null,
    cart: []
  })),
  updateCartItem: (id, updatedItem) =>
    set((state) => {
      const newCart = state.cart.map((item) =>
        item.id === id ? updatedItem : item
      );
      return { cart: newCart };
    }),
}));

export default useStore;
