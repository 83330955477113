import React, { useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext.jsx';
import useStore from '../store/store.js';
import Button from '../ button/Button.jsx';
import { singOutUser } from '../utils/firebase.js';
function Header() {
  const user = useStore((state) => state.user);
  useEffect(()=>{
    console.log(user)
  })

 let clickMe=()=>{
    singOutUser()
  }

  if(user){
    console.log(user)
  }
  return (
    <div className='Header'>
        
        <div  alt="" className="Mainlogo" />
        <div  alt="" className="HeaderLinks">
        
      
      
      <Link
          to={'/contactus'}
          className='signinlink'
        >
        <i class="fa-regular fa-address-book"></i>
        <span>Contact Us</span>
        </Link>
      <Link
          to={'/tandc'}
          className='signinlink'
        >
        <i class="fa-solid fa-shield"></i>
        <span>Privacy</span>
        </Link>

      {user?<Link
          to={'/signin'}
          className='signinlink'
        ><img className='profile-image' src={encodeURI(user.photoUrl)} alt=""/></Link>:<Link
          to={'/signin'}
          className='signinlink'
        >
        <i class="fa-regular fa-user"></i>
        <span>Sign In</span>
        </Link>}

        <Link
          to={'/solocompiler'}
          className='signinlink'
        >
       <i class="fa-solid fa-globe"></i>
        
        </Link>

        </div>

    </div>
  )
}

export default Header