import React, { useEffect, useState } from 'react';
import axios from 'axios';

const OrderManagement = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const baseUrl='https://saliheenbackend.onrender.com'
  // Fetch orders on component mount
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/orders/status/pending`);
        setOrders(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch orders');
        setLoading(false);
      }
    };
    fetchOrders();
  }, []);

  // Handle status change
  const updateOrderStatus = async (orderId, newStatus) => {
    try {
      await axios.put(`${baseUrl}/api/orders/${orderId}`, { status: newStatus });
      setOrders(prevOrders =>
        prevOrders.map(order => 
          order._id === orderId ? { ...order, status: newStatus } : order
        )
      );
    } catch (error) {
      console.error('Failed to update order status', error);
      setError('Failed to update order status');
    }
  };

  if (loading) return <p>Loading orders...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Order Management</h2>
      <table style={styles.table}>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>User Name</th>
            <th>User Contact</th>
            <th>Total Price</th>
            <th>Shipping Address</th>
            <th>Order Items</th>
            <th>Current Status</th>
            <th>Update Status</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <tr key={order._id} style={styles.row}>
              <td>{order._id}</td>
              <td>{order.name}</td>
              <td>{order.contact}</td>
              <td>${order.totalPrice.toFixed(2)}</td>
              <td>
                {order.shippingAddress.street}, {order.shippingAddress.city}, {order.shippingAddress.state}, {order.shippingAddress.zip}, {order.shippingAddress.country}
              </td>
              <td>
                <ul>
                  {order.orderItems.map((item, index) => (
                    <li key={index}>{item.itemName} - Quantity: {item.quantity}</li>
                  ))}
                </ul>
              </td>
              <td>{order.status}</td>
              <td>
                <select
                  value={order.status}
                  onChange={(e) => updateOrderStatus(order._id, e.target.value)}
                  style={styles.select}
                >
                  <option value="Pending">Pending</option>
                  <option value="Shipped">Shipped</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Inline styles for the component
const styles = {
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  heading: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  },
  row: {
    borderBottom: '1px solid #ddd',
  },
  select: {
    padding: '5px',
    borderRadius: '4px',
    backgroundColor: '#f9f9f9',
    border: '1px solid #ddd',
  },
  th: {
    backgroundColor: '#f4f4f4',
    color: '#333',
    padding: '10px',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  td: {
    padding: '10px',
    textAlign: 'left',
    verticalAlign: 'top',
  },
  button:{
    backgroundColor:"black"
  }
};

export default OrderManagement;
