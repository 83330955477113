import React from 'react';
import { Container, Header, Segment, Divider } from 'semantic-ui-react';
import './TermsAndConditions.css';

const TermsAndConditions = () => (
  <div className="terms-wrapper">
    <Container className="terms-container">
      <Segment  style={{ backgroundColor: 'black', color: '#f9f9f9' }}>
        <Header as="h2" color="yellow" textAlign="center" style={{ fontWeight: 700 }}>
          Terms and Conditions
        </Header>
        <p>Welcome to SaliheenPerfumes! These terms and conditions outline the rules and regulations for the use of our website and services, including payment processing through PhonePe.</p>

        <Divider section />

        <Header as="h3" color="yellow">1. Acceptance of Terms</Header>
        <p>By accessing or using our website, you agree to be bound by these terms and conditions. If you do not agree to these terms, please do not use our services.</p>

        <Divider section />

        <Header as="h3" color="yellow">2. Description of Services</Header>
        <p>SaliheenPerfumes provides premium perfumes and fragrances. All payments on our website are securely processed by PhonePe. Please review PhonePe’s terms and conditions for more information.</p>

        <Divider section />

        <Header as="h3" color="yellow">3. User Responsibilities</Header>
        <p>You agree to use our services responsibly and in compliance with all applicable laws. You must provide accurate information when creating an account or placing an order.</p>

        <Divider section />

        <Header as="h3" color="yellow">4. Payments</Header>
        <p>All payments made through our website are processed via PhonePe. By using our payment services, you agree to PhonePe's terms and conditions. Payments are secure, and we ensure your transaction details are confidential.</p>

        <Divider section />

        <Header as="h3" color="yellow">5. Refunds and Cancellations</Header>
        <p>Refunds are available within two days of purchase if eligibility criteria are met. Refunds will be processed within two business days after approval. For cancellations, please contact us at <strong>+91-8072826007</strong> or email us at <strong>admin@Saliheenperfumes.com</strong> within this period.</p>

        <Divider section />

        <Header as="h3" color="yellow">6. Privacy Policy</Header>
        <p>Your privacy is important to us. By using our services, you agree to our Privacy Policy. Please read it carefully.</p>

        <Divider section />

        <Header as="h3" color="yellow">7. Intellectual Property</Header>
        <p>All content on our website, including images, logos, and text, is the property of SaliheenPerfumes and protected by intellectual property laws. You may not use this content without our permission.</p>

        <Divider section />

        <Header as="h3" color="yellow">8. Limitation of Liability</Header>
        <p>SaliheenPerfumes is not responsible for any damages or losses arising from the use of our services or payments made through PhonePe. Users are responsible for their own actions when using our site.</p>

        <Divider section />

        <Header as="h3" color="yellow">9. Governing Law</Header>
        <p>These terms and conditions are governed by the laws of India. By using our services, you agree to submit to the jurisdiction of India.</p>

        <Divider section />

        <Header as="h3" color="yellow">10. Changes to Terms</Header>
        <p>We reserve the right to modify these Terms and Conditions at any time. We encourage you to review these terms periodically for any updates.</p>

        <Divider section />

        <p>If you have any questions about these Terms and Conditions, please contact us at Phone: +91-8072826007.</p>
      </Segment>
    </Container>
  </div>
);

export default TermsAndConditions;
