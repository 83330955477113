import React, { useState } from 'react';
import { ButtonGroup, Button } from 'semantic-ui-react';

const AttarOrPerfume = ({category}) => {
    let selectedCat=category?category=='custom'?'Perfume':'Attar':'Attar';
  const [selected, setSelected] = useState(selectedCat); // Track selected button

  return (
    <ButtonGroup>
      <Button 
        basic 
        color={selected === 'Perfume' ? 'yellow' : 'grey'} 
        onClick={() => setSelected('Perfume')}
      >
        Perfume
      </Button>
      <Button
        basic 
        color={selected === 'Attar' ? 'yellow' : 'grey'} 
        onClick={() => setSelected('Attar')}
      >
        Attar
      </Button>

    </ButtonGroup>
  );
}

export default AttarOrPerfume;
