import React, { useState, useEffect } from 'react';
import './Orders.css'; // Include the CSS for styling

const Orders = ({ userEmail }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseUrl="http://localhost:3002";
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/orders/user/${userEmail}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setOrders(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setLoading(false);
      }
    };

    fetchOrders();
  }, [userEmail]);

  if (loading) {
    return <p>Loading orders...</p>;
  }

  return (
    <div className="orders-container">
      <h1>Orders for {userEmail}</h1>

      <div className="orders-list">
        {orders.map((order) => (
          <div className="order-card" key={order._id}>
            <h2>Order ID: {order._id}</h2>
            <p><strong>Status:</strong> {order.status}</p>
            <p><strong>Total Price:</strong> ₹{order.totalPrice}</p>
            <p><strong>Payment Method:</strong> {order.paymentMethod}</p>
            <p><strong>Order Date:</strong> {new Date(order.createdAt).toLocaleDateString()}</p>

            <p><strong>Shipping Address:</strong></p>
            <p>{order.shippingAddress.street}, {order.shippingAddress.city}, {order.shippingAddress.state}, {order.shippingAddress.zip}, {order.shippingAddress.country}</p>

            <p><strong>Contact:</strong> {order.contact}</p>

            <p><strong>Items:</strong></p>
            <ul>
              {order.orderItems.map((item) => (
                <li key={item._id}>
                  {item.itemName} - {item.quantity} x ₹{item.itemPrice}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Orders;
