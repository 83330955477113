import React from 'react';
import ShopLocationButton from './ShopLocationButton';
import ShopLocationMap from './ShopLocationMap';
import ButtonExampleSocial from './ButtonExampleSocial';

const Contact = () => {
  return (
    <div className="contact-page">
      <section className="contact-section">
        <div className="container">
          <div className="row">
            {/* Contact Information */}
            <div className="contact-info">
              <ContactInfoItem icon="fas fa-home" title="Address" text="4671 Sugar Camp Road, Owatonna, Minnesota, 55060" />
              <ContactInfoItem icon="fas fa-phone" title="Phone" text="571-457-2321" />
              <ContactInfoItem icon="fas fa-envelope" title="Email" text="ntamerrwael@mfano.ga" />
            </div>

            {/* Contact Form */}
            <div className="contact-form">
              <form id="contact-form">
                <h2>Send Message</h2>
                <FormInput type="text" name="fullName" placeholder="Full Name" required />
                <FormInput type="email" name="email" placeholder="Email" required />
                <FormInput type="textarea" name="message" placeholder="Type your Message..." required />
                <button type="submit">Send</button>
              </form>
            </div>
          </div>
        </div>

        {/* Additional Components */}
        <ButtonExampleSocial />
        <ShopLocationMap />
        <ShopLocationButton />

        
      </section>
    </div>
  );
};

const ContactInfoItem = ({ icon, title, text }) => (
  <div className="contact-info-item">
    <div className="contact-info-icon">
      <i className={icon}></i>
    </div>
    <div className="contact-info-content">
      <h4>{title}</h4>
      <p>{text}</p>
    </div>
  </div>
);

const FormInput = ({ type, name, placeholder, required }) => (
  <div className="input-box">
    {type === "textarea" ? (
      <textarea name={name} placeholder={placeholder} required={required}></textarea>
    ) : (
      <input type={type} name={name} placeholder={placeholder} required={required} />
    )}
  </div>
);

export default Contact;
