import React from 'react';

const ShopLocationButton = () => {
  const openGoogleMaps = () => {
    // Replace with your shop's latitude and longitude
    const latitude = 10.991396;
    const longitude = 76.965440;
    const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    // Open Google Maps in a new tab
    window.open(googleMapsUrl, '_blank');
  };

  return (
    <button onClick={openGoogleMaps} style={buttonStyle}>
      Locate Us
    </button>
  );
};

// Optional: Add some styling to the button
const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  fontSize: '16px',
  width:'100%',
  marginLeft:'auto',
  marginRight:'auto'
};

export default ShopLocationButton;
