import React from "react";
import PerItem from "./PerItem.jsx";
// Import all images
const importAll = (r) => r.keys().map(r);
const images = importAll(
  require.context("../assets/custom", false, /\.(png|jpe?g|svg)$/)
);

function Custom() {
  return (
    <PerItem images={images} category={'custom'} type={'attar'}/>
  );
}
export default Custom;