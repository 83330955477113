import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signOut
} from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBTojBTxX1l386-xSgRm-NmWqD-yPEMyOQ",
  authDomain: "shopping-8488e.firebaseapp.com",
  projectId: "shopping-8488e",
  storageBucket: "shopping-8488e.appspot.com",
  messagingSenderId: "861015164017",
  appId: "1:861015164017:web:378b0224c100932079c7d0",
};

const app = initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();

provider.setCustomParameters({
  prompt: "select_account",
});
export const auth = getAuth();

export const signInWithGooglePop = () => signInWithPopup(auth, provider);

export const db = getFirestore();

export const createUserDocument = async (user) => {
  const userDoc = doc(db, "users", user.uid);

  const usersnap = await getDoc(userDoc);

  if (!usersnap.exists()) {
    const { displayName, email,photoURL } = user;
    const createdAt = new Date();

    try {
      await setDoc(userDoc, {
        displayName,
        email,
        createdAt
      });
      return await getDoc(userDoc).data();
    } catch (e) {
      console.log(e);
    }
    const newDoc = await setDoc();
  }else{
    return usersnap.data();
  }
};

export const createAuthUserWithEmailandPass=async (email,password)=>{
    if(!email || !password) return;
    return await createAuthUserWithEmailandPass(auth,email,password);
} 


export const singOutUser=async ()=>{
  console.log('clicked signout')
  return await signOut(auth);
}
