import React, { useState } from "react";
import {
  createAuthUserWithEmailandPass,
  signInWithGooglePop,
  createUserDocument,
} from "../utils/firebase";
import FormInput from "./FormInput";
import Button from "../ button/Button";
const defaultValues = {
  email: "",
  password: "",
};



function SignIn({ children }) {
  const [formFields, setFormFields] = useState(defaultValues);
  const { email, password } = formFields;
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newuser = await createAuthUserWithEmailandPass(email, password);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div>
      <h1>Already Have an Account</h1>
      <span>Sign In with your Email and Password</span>
      <form onSubmit={handleSubmit}>
        <FormInput
          label={"Email"}
          type="email"
          required={true}
          onChange={handleChange}
          value={email}
          name={"email"}
        />
        <FormInput
          label={"Password"}
          type="password"
          required={true}
          onChange={handleChange}
          value={password}
          name={"password"}
        />

        <div className="buttons-container">
        <Button type="submit">Sign In</Button>
        
        
        </div>
      </form>
      
      {children}
    </div>
  );
}

export default SignIn;
