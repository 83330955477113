import React from "react";
import PerItem from "./PerItem.jsx";
// Import all images
const importAll = (r) => r.keys().map(r);
const images = importAll(
  require.context("../assets/inspired", false, /\.(png|jpe?g|svg)$/)
);

function Inspired() {
  return (
    <PerItem images={images} category={'inspired'} type={'attar'}/>
  );
}
export default Inspired